body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: #000;
}

p .size-text {
  font-size: 1.3rem !important;
}

.button-resized {
  padding-top: 0.1rem;
}

label {
  font-weight: 600;
  font-size: 16px !important;
}


.project-color {
  background-color: #276678;
}

.project-text-color {
  color: #276678;
}